import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { Link } from "gatsby"
import { FaExternalLinkAlt, FaLongArrowAltRight } from "react-icons/fa"
import { BsSkipBackward } from "react-icons/bs"
import { IconContext } from "react-icons"

const IconBack = () => (
  <IconContext.Provider value={{ color: "gray", size: "1.32em" }}>
    <BsSkipBackward />
  </IconContext.Provider>
)

const RightArr = () => (
  <IconContext.Provider value={{ color: "#008742", size: "1em" }}>
    <FaLongArrowAltRight />
  </IconContext.Provider>
)

const OutArr = () => (
  <IconContext.Provider value={{ color: "#E50012", size: "1em" }}>
    <FaExternalLinkAlt />
  </IconContext.Provider>
)

const StyledDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 3px;
    padding: 0.32em 1.62em;
    margin: 20px 30px 80px;
    transition: 0.6s;
    overflow: hidden;
    svg {
      margin-left: 0.62em;
      transition: 0.2s;
    }
    &.outLink {
      border: solid 2px ${({ theme }) => theme.colors.primary.red};
      color: ${({ theme }) => theme.colors.primary.red};
      &:hover {
        background: ${({ theme }) => theme.colors.primary.red};
        color: white;
        transform: translateX(5px);
        svg {
          opacity: 0;
        }
      }
    }
    &.innerLink {
      border: solid 2px ${({ theme }) => theme.colors.primary.green};
      color: ${({ theme }) => theme.colors.primary.green};
      &:hover {
        background: ${({ theme }) => theme.colors.primary.green};
        color: white;
        transform: translateX(5px);
        svg {
          opacity: 0;
        }
      }
    }
    &.backLink {
      color: gray;
      align-items: center;
      svg {
        margin-right: 0.32em;
      }
    }
  }
  ${() =>
    media.sp(css`
      flex-flow: column wrap;
      padding-bottom: 50px;
      a {
        margin-bottom: 0;
      }
    `)}
`

const CompanyInfoFooterLinks = props => {
  const renderLink = () => {
    if (props.outLink === "https://www.francois.co.jp/sweets-sweets/") {
      return (
        <StyledDiv>
          <Link className="backLink" to={props.backLink}>
            <IconBack />
            事業一覧に戻る
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="outLink"
            href={props.outLink}
          >
            スイーツ・スイーツ商品ページ
            <OutArr />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="outLink"
            href="https://www.francois.co.jp/hitokuchido/"
          >
            一口堂商品ページ
            <OutArr />
          </a>
        </StyledDiv>
      )
    } else {
      return (
        <StyledDiv>
          {props.backLink && (
            <Link className="backLink" to={props.backLink}>
              <IconBack />
              事業一覧に戻る
            </Link>
          )}
          {props.outLink && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="outLink"
              href={props.outLink}
            >
              ウェブサイト
              <OutArr />
            </a>
          )}
          {props.innerLink && (
            <Link className="innerLink" to={props.innerLink}>
              会社情報
              <RightArr />
            </Link>
          )}
        </StyledDiv>
      )
    }
  }
  return renderLink()
  // <StyledDiv>
  //   {props.backLink &&
  //     (<Link className="backLink" to={props.backLink}><IconBack />事業一覧に戻る</Link>)}
  //   {props.outLink &&
  //     <a target="_blank" rel="noopener noreferrer" className="outLink" href={props.outLink}>ウェブサイト<OutArr /></a>}
  //   {props.innerLink &&
  //     <Link className="innerLink" to={props.innerLink}>会社情報<RightArr /></Link>}
  // </StyledDiv>
}

export default CompanyInfoFooterLinks
