import React from "react"
import Image from "gatsby-image"
import styled from "styled-components"

const StyledDiv = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -3;
  }
  .headerContents {
    position: absolute;
    z-index: 1;
    .gatsby-image-wrapper {
      width: 100px;
    }
    p {
      color: white;
      font-size: 1.62em;
    }
  }
`

const CompanyInfoHeader = ({ heroImage, logoImage, logoText }) => {
  return (
    <StyledDiv>
      {heroImage && <Image fluid={heroImage} />}
      <div className="headerContents">
        {logoImage && (
          <Image fluid={logoImage} alt={logoText ? logoText : ""} />
        )}
      </div>
    </StyledDiv>
  )
}

export default CompanyInfoHeader
